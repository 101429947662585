import React, { useState, useEffect, useRef } from 'react';
import './style.css';
import { useUserAuth } from "../context/UserAuthContext";
import { Footer } from './components/Footer';
import { Link, useNavigate } from 'react-router-dom';
import { AddPatientButton } from './components/AddPatientButton';
import Spinner from 'react-bootstrap/Spinner'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { WeeklyUpcomingAppointments } from './components/WeeklyUpcomingAppointments';
import { ProgramRenewalBtn } from './components/ProgramRenewalBtn';
import moment from 'moment';
import { analytics } from "../firebase/index";
import { logEvent } from "firebase/analytics";
import NoInternetPopup from './components/NoInternetPopup';
import { Platform } from '@capacitor/core';
import search from '../images/Search.png';
import sort from '../images/sort1.png'
import sort1 from '../images/sort.png'
import done from '../images/Done.png'
import arrow from '../images/Expand Arrow.png';
import { useIonRouter } from '@ionic/react';
import { App } from '@capacitor/app';
import WhatsAppButton from './components/WhatsAppButton';
import ImageVideoSlider from './components/ImageVideoSlider';


export const Home = () => {
  const { auth, ID, user, setUser, setEvent, setDocEvent, fetchPhysioData, GetAllPhysioData, patientActiveProgram, getPhysioPatients, fetchPatientScore, fetchCompleteSlotData, fetchCompleteSlotDocData, updateHomePageData, getAllPrograms } = useUserAuth();
  const [userName, setUserName] = useState('');
  const [patientsData1, setPatientsData1] = useState([]);
  const [patientsData, setPatientsData] = useState([]);
  const [patient, setPatient] = useState([]);
  const [loading, setLoading] = useState(true);
  const [scoreloading, setScoreLoading] = useState(true);
  const [sortDescending, setSortDescending] = useState(false);
  const [sortAescending, setSortAescending] = useState(false);
  const [showActive, setShowActive] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSortPopup, setShowSortPopup] = useState(false);
  const [showActivePatients, setShowActivePatients] = useState(true);
  const [showInactivePatients, setShowInactivePatients] = useState(true);
  const inputRef = useRef(null);
  const bookedColors = ['#FADB8C', '#FADB8C', '#FADB8C', '#FADB8C'];

  
  const ionRouter = useIonRouter();
  document.addEventListener('ionBackButton', (ev) => {
    ev.detail.register(-1, () => {
      console.log("checking android backbutton")
      if (!ionRouter.canGoBack()) {
        App.exitApp();
      }
    });
  });
  

  const navigate = useNavigate();

  document.addEventListener('ionBackButton', (ev) => {
    ev.detail.register(5, () => {
      console.log('Another handler was called!');
    });
  
    ev.detail.register(10, (processNextHandler) => {
      console.log('Handler was called!');
  
      processNextHandler();
    });
  });

  const handleSortClick = () => {
    setSortDescending((prevSortDescending) => !prevSortDescending);
    setSortAescending(false);

  };

  const handleSortClick1 = () => {
    setSortAescending(!sortAescending);
    setSortDescending(false);
    console.log("this function is called ",)
    console.log("the value of sort aecending ",sortAescending);
  };

  const handleToggleActive = () => {
    setShowActive(!showActive);
  };

  const handleSearch = (e) => {
    const inputRect = inputRef.current.getBoundingClientRect();
    console.log("value ", inputRect.top, window.scrollY + inputRect.top + 10)
    window.scrollTo(0, window.scrollY + inputRect.top - 10);
    setSearchTerm(e.target.value);
  };

  


  const handleSearchScroll = (e) => {
    const inputRect = inputRef.current.getBoundingClientRect();
    console.log("value ", inputRect.top, window.scrollY + inputRect.top + 10)
    window.scrollTo(0, window.scrollY + inputRect.top - 10);
  };
  // useEffect(() => {
  //   // Scroll to the top when the input bar is clicked or value changes
  //   window.scrollTo(0, 0);
  // }, [searchTerm]);

  const handleFilterClick = () => {
    setShowSortPopup(!showSortPopup);
  };

  const handleToggleActivePatients = () => {
    setShowActivePatients((prev) => !prev);
  };

  const handleToggleInactivePatients = () => {
    setShowInactivePatients((prev) => !prev);
  };

  // checking user is logged in or not , if not it will direct it to login page "/phone"
  useEffect(() => {
    if (!localStorage.getItem('userData')) {
      console.log("navigation to / as we dont get userData in local")
      navigate("/");
    } else {
      const checkUserData = async () => {
        const userData = await fetchPhysioData();
        if (!userData) {
        console.log("navigation to / as we dont get userData in firebase , user, ",ID,user);

          setUser(null);
          navigate("/");
        }
      };
      checkUserData();
    }
  }, [user, setUser, navigate]);

  // fetching user data 
  useEffect(() => {
    if (user) {
      
      setUserName(localStorage.getItem('py_username'));
      
      // console.log("user details ",user);
      const fetchData = async () => {
        // console.log('Fetching user data...');
        const userData = await fetchPhysioData();
        if (userData) {
          setUserName(userData.Name);
        }
        logEvent(analytics, 'py_home_page_log_pydata', {
          Date: new Date().toLocaleString(),
          ID,
          "pyData": JSON.stringify(userData),
          "py_log" : "fetching home page data for physio"
        });  
      };
      
      fetchData();
    }
  }, [user]); // Add user as a dependency

  useEffect(() => {
    const fetchAndProcessPatients = async () => {
      setLoading(true);

      // Fetch local data from localStorage
      const localData = JSON.parse(localStorage.getItem('Data')) || [];
      console.log("local patient data 1", localData);

      if (localData.length > 0) {
        setPatientsData(localData);
        setLoading(false);
      }

      // Fetch data from Firebase
      if(user){
        const data = await GetAllPhysioData();
        console.log("called single firebase function and got the data ", data);
        setPatientsData(data); // Update state with initial data immediately
        console.log("the state value ", patientsData);
        setLoading(false);
  
  
        // Process patient data
        const updatedPatientsData = await Promise.all(
          data.map(async (patient) => {
            const patientId = patient.Patient_idFP;
            console.log("this is the patient ", patientId);
            const patientScore = await fetchPatientScore(patientId);
            const program = await patientActiveProgram(patientId);
            console.log("this is the patient score ", program, patientId, patientScore);
            return {
              ...patient,
              PatientTotalScore: patientScore.GrandTotalScore,
              active: program.length > 0 ? program[0].active : "False",
            };
          })
        );
  
        // Update state with the processed data
        setPatientsData(updatedPatientsData); // Update with processed data
        localStorage.setItem('Data', JSON.stringify(updatedPatientsData));
        // updateHomePageData(updatedPatientsData);
        console.log("this is patient data in home page ----------111", updatedPatientsData);
        setLoading(false);
      }
      
    };
    fetchAndProcessPatients();

  }, [user]);

  //Fetching program data and calender
  useEffect(() => {
    const fetchData = async () => {
      try {
        const programsData = await getAllPrograms();
        localStorage.setItem('Program', JSON.stringify(programsData));
        const completeSlotData = await fetchCompleteSlotData();
        if (completeSlotData) {
          // Process the data and convert it into events format
          const newEvents = [];
          for (const date in completeSlotData) {
            completeSlotData[date].forEach((slot, index) => {
              const isBooked = slot.ID.startsWith('booked');
              const color = isBooked ? bookedColors[index % bookedColors.length] : undefined;
              const eventTitle = slot.Name || 'Blocked';
              newEvents.push({
                id: newEvents.length + 1,
                ID: `${slot.ID}`,
                title: eventTitle,
                start: moment(`${date} ${slot.start}`, 'DD/MM/YYYY HH:mm').toDate(),
                end: moment(`${date} ${slot.end}`, 'DD/MM/YYYY HH:mm').toDate(),
                className: isBooked ? 'booked-event' : "not-yetbooked",
                color: color,
              });
            });
          }
          console.log("this is actual calender event  ",newEvents);
          localStorage.setItem('CalenderEvents', JSON.stringify(newEvents));
          setEvent(newEvents);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchData();
  }, [user]);


  // fetching doc Calender Data 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const completeSlotData = await fetchCompleteSlotDocData();
        if (completeSlotData) {
          // Process the data and convert it into events format
          const newEvents = [];
          for (const date in completeSlotData) {
            completeSlotData[date].forEach((slot, index) => {
              let isBooked = false;
  
              try {
                isBooked = slot.ID && slot.ID.startsWith('booked');
              } catch {
                isBooked = false;
              }
  
              const color = isBooked ? bookedColors[index % bookedColors.length] : undefined;
              const eventTitle = slot.Name || 'Blocked';
  
              // Ensure properties exist before accessing them
              const start = slot.start ? new Date(date + ' ' + slot.start) : null;
              const end = slot.end ? new Date(date + ' ' + slot.end) : null;
  
              newEvents.push({
                id: newEvents.length + 1,
                ID: `${slot.ID}`,
                title: eventTitle,
                start: start,
                end: end,
                className: isBooked ? 'booked-event' : "not-yetbooked",
                color: color,
                appointmentType: slot.appointmentType || '',
                appointmentStartTime: slot.appointmentStartTime || '',
                day: slot.day || '',
                remainingMinutes: slot.remainingMinutes || '',
                slotDuration: slot.slotDuration || '',
                patientName: slot.patientname || '', // Add check for patientName
                patientNumber: slot.patientnumber || '' // Add check for patientNumber
              });
            });
          }
          
          console.log("this is actual calendar event  ", newEvents);
          localStorage.setItem('DocCalenderEvents', JSON.stringify(newEvents));
          // setEvents(newEvents);
          setDocEvent(newEvents);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchData();
  }, [user]);


  // useEffect (() =>{
  //   const newFetchPatientData = async () => {
  //     const data = await GetAllPhysioData();
  //     console.log("called single firebase function and got the data ", data);
  //     setPatientsData(data); 
  //     setLoading(false);

  //   }
  //   newFetchPatientData();
  // },[user])

  useEffect(() =>{
    console.log("printing patients Data ",patientsData);
  },[patientsData])
  

  // fetching patient Score 
  // const fetchIndiPatientScore = async (patientId) => {
  //   // console.log("fetching Scores ...")
  //   try {
  //     const score = await fetchPatientScore(patientId); // Implement this function to fetch the score
  //     // console.log("Scores ",score);
  //     return score;
  //   } catch (error) {
  //     console.error(`Error fetching score for patient ID ${patientId}:`, error);
  //     return 'N/A'; // Return 'N/A' or handle the error as needed
  //   }
  // };

  const handleLinkClick = (id) => {
    console.log("logging patient data for no use")
    // Log the patient ID when the link is clicked
    logEvent(analytics, 'py_PatientLinkClick', {
      Date: new Date().toLocaleString(),
      ID,
      PatientID: id,
      "py_log": "Patient link clicked"
    });
  }

 

  return (
    <div  className='background'>
      <br />
      <div className='text-style'>
        <div className='name-label'>{userName=='' ? <Skeleton width={200} style={{ backgroundColor: 'rgba(253, 220, 189, 0.9)' }} /> : `Hi ${userName}`}</div>
        {/* <br /> */}
        {/* <ImageVideoSlider/> */}
        <WeeklyUpcomingAppointments/>
        

        {/* <br /> */}
        {/* <ProgramRenewalBtn/>                */}
        {/* <br /> */}

        <div className='home-main-container'>

          {/* Program Renewal Banner */}
          <div className='mypatient-header'>
            My Patients
          </div>
          

          {loading ? (
          <div className='skeleton '>
            <Skeleton
              sx={{ bgcolor: 'grey.900' }}
              variant="rectangular"
              className='home-loader'
              height={70}
            /> 
            <Skeleton
              sx={{ bgcolor: 'grey.900' }}
              variant="rectangular"
              className='home-loader'
              height={70}
            /> 
            <Skeleton
              sx={{ bgcolor: 'grey.900' }}
              variant="rectangular"
              className='home-loader'
              height={70}
            /> 
            <Skeleton
              sx={{ bgcolor: 'grey.900' }}
              variant="rectangular"
              className='home-loader'
              height={70}
            /> 
          </div>
            
          ) : (
            <>
              <img className='search-icon' src={search} alt="Search Icon" />
              <div style={{display:"flex"}}>
                <input
                  ref={inputRef}
                  type='text'
                  placeholder = 'Search for patients'
                  value={searchTerm}
                  onChange={handleSearch}
                  onClick={handleSearchScroll}
                  className='patient-search'
                />

                <div className='filter-block' onClick={handleFilterClick}>
                  <img src={sort} alt="" style={{height:"35px", paddingLeft:"8%"}} /> <div style={{fontSize:"80%", fontWeight:"500"}}> Sort by </div> 
                </div>
              </div>

              {showSortPopup && (
                <>
                  <div className="backdrop" onClick={handleFilterClick}></div>
                  <div className='sort-popup'>
                    {/* <p onClick={handleSortClick}>Sort by Score</p> */}
                    {/* Add more sorting options as needed */}
                    <div>
                      <img src={sort1} alt="" style={{height:"20px", paddingRight:"2%"}} /> Sort By
                    </div>
                    <br />
                    <div onClick={() => {handleSortClick(); handleFilterClick();}} style={{display:"flex", justifyContent:"space-between"}}>
                      Score : High to Low {sortDescending && <img style={{height: "25px", paddingLeft:"20%"}}src={done}/>}

                    </div>
                    <hr />
                    <div onClick={() => {handleSortClick1(); handleFilterClick();}} style={{display:"flex", justifyContent:"space-between"}}>
                      Score : Low to High {sortAescending && <img style={{height: "25px"}} src={done}/>}

                    </div>
                  </div>
                </>
              )}

              
              <div>
              <div className='mypatient-number mypatient-dropdown' onClick={handleToggleActivePatients}>
                <div style={{display:"flex", paddingTop:"1%x"}}> {patientsData.filter((patient) => patient.active === 'True').length} <p style={{ color:"#00621B"}}> &nbsp;Active&nbsp;</p>patients </div> <img src={arrow} className='dropdownimg'/>
              </div >
                { showActivePatients && patientsData
                  .filter((patient) =>
                    patient.PatientNameFP.toLowerCase().includes(searchTerm.toLowerCase())
                  )
                  .slice()
                  .sort((a, b) => {
                    if (sortDescending) {
                      return b.PatientTotalScore - a.PatientTotalScore;
                    } else if (sortAescending) {
                      return a.PatientTotalScore - b.PatientTotalScore;
                    } else {
                      return a.PatientNameFP.localeCompare(b.PatientNameFP);
                    }
                  })
                  .filter((patient) => patient.active === "True")
                  .map((patient, index) => {
                    const patientScore = patient.PatientTotalScore;
                    const patientId = patient.Patient_idFP;
                    const scoreColor = patientScore === 'N/A' || parseInt(patientScore) < 30 ? 'red' : 'green';
                    
                    return (
                      <Link
                        to={{
                          pathname: `/patientprofile/${encodeURIComponent(patient.Patient_idFP)}/${encodeURIComponent(patient.PatientNameFP)}/${encodeURIComponent(patient.PatientMobileFP)}`,
                          state: { patientData: patient }
                        }}
                        key={patient.Patient_idFP}
                        style={{ textDecoration: 'none' }}
                        onClick={() => handleLinkClick(patient.Patient_idFP)}
                        className='dropdown-content'
                      >
                        <div className='patients'>
                          <div className='patient-block1'>
                            <div className='patient-name'>{patient.PatientNameFP}</div>
                            <div className='patient-issue'>{patient.PatientTreatmentFP} </div>
                          </div>
                          <div className='patient-block2'>
                            {patientScore === 'N/A' ? (
                              <Spinner animation="grow" style={{ color: "#6E7382" }} />
                            ) : (
                              <>
                                <div className={`${parseInt(patientScore) < 30 ? 'patient-alert' : ''}`}>
                                  {patientScore === 'N/A' ? '' : parseInt(patientScore) < 30 ? 'Low Score' : ''}
                                </div>
                                <div className='patient-score' style={{ color: scoreColor }}>
                                  {patientScore === undefined ? 'N/A' : `${patientScore}%`}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </Link>
                    );
                  })}
              </div>

              <div>
              <div className='mypatient-number mypatient-dropdown' onClick={handleToggleInactivePatients} style={{paddingBottom:"2%"}}>
                {patientsData.filter((patient) => patient.active === 'False').length} Inactive patients    <img src={arrow} className='dropdownimg' />
              </div>
                {showInactivePatients && patientsData
                  .filter((patient) =>
                    patient.PatientNameFP.toLowerCase().includes(searchTerm.toLowerCase())
                  )
                  .slice()
                  .sort((a, b) => {
                    if (sortDescending) {
                      return b.PatientTotalScore - a.PatientTotalScore;
                    } else if (sortAescending) {
                      return a.PatientTotalScore - b.PatientTotalScore;
                    } else {
                      return a.PatientNameFP.localeCompare(b.PatientNameFP);
                    }
                  })
                  .filter((patient) => patient.active === "False")
                  .map((patient, index) => {
                    const patientScore = patient.PatientTotalScore;
                    const patientId = patient.Patient_idFP;
                    const scoreColor = patientScore === 'N/A' || parseInt(patientScore) < 30 ? 'red' : 'green';

                    return (
                      <Link
                        to={{
                          pathname: `/patientprofile/${encodeURIComponent(patient.Patient_idFP)}/${encodeURIComponent(patient.PatientNameFP)}/${encodeURIComponent(patient.PatientMobileFP)}`,
                          state: { patientData: patient }
                        }}
                        key={patient.Patient_idFP}
                        style={{ textDecoration: 'none' }}
                        onClick={() => handleLinkClick(patient.Patient_idFP)}
                        className='dropdown-content'
                      >
                        <div className='patients'>
                          <div className='patient-block1'>
                            <div className='patient-name'>{patient.PatientNameFP}</div>
                            <div className='patient-issue'>{patient.PatientTreatmentFP} </div>
                          </div>
                          <div className='patient-block2'>
                            {patientScore === 'N/A' ? (
                              <Spinner animation="grow" style={{ color: "#6E7382" }} />
                            ) : (
                              <>
                                <div className={`${parseInt(patientScore) < 30 ? 'patient-alert' : ''}`}>
                                  {patientScore === 'N/A' ? '' : parseInt(patientScore) < 30 ? 'Low Score' : ''}
                                </div>
                                <div className='patient-score' style={{ color: scoreColor }}>
                                  {patientScore === undefined ? 'N/A' : `${patientScore}%`}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </Link>
                    );
                  })}
              </div>

            </>
          )}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        
        </div>


        
          
        
      </div>
      <WhatsAppButton/>

      <AddPatientButton/>
      
      <Footer/>

    </div>
  );
};
