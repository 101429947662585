import React, { useEffect, useState } from 'react';
import { useUserAuth } from '../context/UserAuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import left from '../images/Left.png';
import Thumbnail from '../images/thumbnail.png';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { analytics } from "../firebase/index";
import { logEvent } from "firebase/analytics";
import NoInternetPopup from './components/NoInternetPopup';
import spinner from '../images/loaderr.gif'; // Placeholder spinner image

export const PlanView = () => {
  const navigate = useNavigate();
  const { ID, getPlan } = useUserAuth();
  const { planId, planName, code } = useParams();
  const [planData, setPlanData] = useState(null);
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [url, setUrl] = useState(null);
  const [videoKey, setVideoKey] = useState(0);
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const [loadedImages, setLoadedImages] = useState({});

  useEffect(() => {
    const fetchPlan = async () => {
      logEvent(analytics, 'py_plan_page', {
        Date: new Date().toLocaleString(),
        ID,
        planID: planId,
        py_log: "Plan Page "
      });
    };

    fetchPlan();
  }, [planId]);

  useEffect(() => {
    const fetchPlan = async () => {
      console.log("Plan ID: ", planId);
      const fetchedPlanData = await getPlan(planId);
      console.log("this is fetched plan ", fetchedPlanData);

      const planDataWithImages = {
        weightage: fetchedPlanData.weightage,
        name: fetchedPlanData.name,
        reps: fetchedPlanData.reps,
        imgSrc: fetchedPlanData.name.map((exerciseName) =>
          `https://firebasestorage.googleapis.com/v0/b/snaiyu-7b192.appspot.com/o/PhysioExcercisePlanImages%2F${exerciseName}.png?alt=media&token=5021fba3-9698-4ae2-b7b6-f259ef69d9f9`
        ),
      };
      console.log('Plan Data with Images:', planDataWithImages);
      setPlanData(planDataWithImages);
      setSelectedExercise(planDataWithImages.name[0]);

      const firstExerciseName = planDataWithImages.name[0].replace(/\s+/g, '');
      const firstExerciseUrl = `https://firebasestorage.googleapis.com/v0/b/snaiyu-7b192.appspot.com/o/PhysioExerciseVideo%2F${firstExerciseName}.mp4?alt=media&token=5021fba3-9698-4ae2-b7b6-f259ef69d9f9`;
      setUrl(firstExerciseUrl);
      setVideoKey((prevKey) => prevKey + 1);
    };

    fetchPlan();
  }, [planId]);

  const handleExerciseClick = (exerciseName) => {
    const formattedExerciseName = exerciseName.replace(/\s+/g, '');
    setSelectedExercise(exerciseName);
    const newUrl = `https://firebasestorage.googleapis.com/v0/b/snaiyu-7b192.appspot.com/o/PhysioExerciseVideo%2F${formattedExerciseName}.mp4?alt=media&token=5021fba3-9698-4ae2-b7b6-f259ef69d9f9`;
    setUrl(newUrl);
    setVideoKey((prevKey) => prevKey + 1);
    setIsVideoLoading(true); // Reset video loading state
  };

  const handleImageLoad = (exerciseName) => {
    setLoadedImages((prev) => ({ ...prev, [exerciseName]: true }));
  };

  return (
    <div className='plan-page'>
      <br />
      <NoInternetPopup />
      <div className='plan-header'>
        <div style={{ paddingLeft: "5%", paddingTop: "5%" }}>
          <img
            className='back-image'
            src={left}
            alt="back"
            onClick={() => navigate('/myprograms')}
          />
          &nbsp; &nbsp; <br />
          <div className='pprofile-pname-container'>
            <div className='pprofile-pname'>
              {planName}{code !== 'NA' && ` - ${code}`}
            </div>
          </div>
        </div>
        <br />
        <div className='video-container'>
          {/* {isVideoLoading && (
            <div className='video-loading'>
              <img src={spinner} alt="Loading..." />
            </div>
          )} */}
          <video
            controls
            key={videoKey}
            poster={Thumbnail}
            onCanPlayThrough={() => setIsVideoLoading(false)}
          >
            <source src={url} type="video/mp4" />
          </video>
        </div>
      </div>
      <div className='plan-content'>
        {planData && planData.name && planData.reps ? (
          <div>
            {planData.name.map((exerciseName, index) => (
              <div
                key={exerciseName}
                className={`plan-block ${selectedExercise === exerciseName ? 'selected-plan-block' : ''}`}
                onClick={() => handleExerciseClick(exerciseName)}
              >
                <div>
                  <img
                    className='plan-img'
                    src={planData.imgSrc[index]}
                    alt={exerciseName}
                    onLoad={() => handleImageLoad(exerciseName)}
                    onError={(e) => {
                      console.error(`Failed to load image: ${planData.imgSrc[index]}`, e);
                      e.target.src = planData.imgSrc[0]; // Fallback to spinner if image fails to load
                    }}
                  />
                </div>
                <div className='plan-text'>
                  <div className='plan-name'>
                    {exerciseName}
                  </div>
                  <div className='plan-reps'>
                    Reps: {planData.reps[index]}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className='skeleton' style={{ marginLeft: "5%" }}>
            <Skeleton
              sx={{ bgcolor: 'grey.900' }}
              variant="rectangular"
              className='home-loader'
              height={80}
            /> <br />
            <Skeleton
              sx={{ bgcolor: 'grey.900' }}
              variant="rectangular"
              className='home-loader'
              height={80}
            /> <br />
            <Skeleton
              sx={{ bgcolor: 'grey.900' }}
              variant="rectangular"
              className='home-loader'
              height={80}
            /> <br />
          </div>
        )}
      </div>
    </div>
  );
};
